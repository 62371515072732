import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: { primary: { main: "#689F38" }, secondary: { main: "#00BCD4" } },
  typography: {
    fontFamily: ["PT Sans", "sans-serif"].join(","),
  },
});

export default theme;
